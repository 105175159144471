@use './oeo-variables.scss' as variables;
@import '@overtur-monorepo/phx-angular-material/assets/config/colors';

@mixin scroll-bar-track {
  width: 0.625rem;
  background-color: $gray200;
  border-left: solid 1px $gray100;
  height: 0.625rem;
}

@mixin scroll-bar {
  background-color: $gray500;
  border-bottom: solid 1px $gray500;
  border-top: solid 1px $gray500;
}

@mixin typography-smaller {
  font-size: 14px;
  letter-spacing: 0.25px;
}
@mixin empty-header {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.15px;
  line-height: 28px;
  margin: 0;
}

//  Font Awesome In Content
@mixin font-awesome {
  display: inline-block;
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-variant: normal;
  text-decoration: inherit;
  text-rendering: auto;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
}

// Material icons
@mixin material-icon() {
  direction: ltr;
  display: inline-block;
  font-family: 'Material Icons';
  font-feature-settings: 'liga';
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

@mixin oeo-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px transparent;
  border-radius: 4px;
  background: transparent;
  color: $gray900;
  font-family: variables.$font-family;
  font-style: normal;
  font-size: 1em;
  font-weight: normal;

  &:disabled {
    background: $gray200;
    color: $gray500;
    cursor: default;
  }
  &:hover:not(:disabled) {
    border: solid 1px $gray900;
    background-color: transparent;
    cursor: pointer;
  }
  &:focus {
    outline: none;
  }
  transition: border-color 0.3s ease-in-out;
}

@mixin input-type($icon) {
  padding-left: calc(0.375em + 2.5ch);
  background-image: url('/assets/img/icons/#{$icon}.svg');
  background-size: 1.5ch;
  background-repeat: no-repeat;
  background-position: 0.5ch center;
  &:not(:hover, :focus) {
    padding-left: calc(0.375em + 2.5ch);
  }
}

@mixin form-field {
  background-color: rgba(0, 0, 0, 0);
  color: $gray900;
  border: solid 2px $gray300;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  font-family: variables.$font-family;
  font-style: normal;
  font-size: 1em;
  font-weight: normal;
  line-height: 1.25;
  padding: 0.375em;
  width: 100%;
  &:hover {
    border: solid 2px $gray900;
  }
  &:focus {
    border: solid 2px $blue500;
  }
  &.ng-invalid.ng-dirty,
  &.duplicate,
  &.error {
    color: $red400;
    background-image: url('/assets/img/error.svg');
    background-position: calc(100% - 0.75em) center;
    background-size: 1.3125em 1.125em;
    background-repeat: no-repeat;
    background-color: #e3485014;
    padding-right: 2.5625em;
    &:hover,
    &:focus {
      border-color: $red700;
    }
  }
  transition: border-color 0.3s ease-in-out;
}
