@import '@overtur-monorepo/phx-angular-material/assets/config/colors';

/* mat-tab-group styling */
mat-tab-group.mat-mdc-tab-group {
  &.oeo-hidden-mat-tab-group-header {
    mat-tab-header.mat-mdc-tab-header {
      display: none;
    }
    mat-ink-bar.mat-mdc-ink-bar {
      background-color: $blue500;
    }
  }
}
.mat-mdc-tab-link {
  font-size: 16px;
  font-weight: 400;
  &.oeo-mat-tab-link {
    opacity: 1;
    display: flex;
    gap: 1em;
    user-select: none;
  }
  &.mat-mdc-tab-label-active {
    color: $blue500;
  }
}
