@use '@angular/material' as mat;
@use 'oeo-variables' as variables;
@use 'oeo-mixins' as oeo-mixins;
@import '@overtur-monorepo/phx-angular-material/assets/config/colors';

mat.$theme-ignore-duplication-warnings: true;

/* For use in src/lib/core/theming/_palette.scss */

.hide-page-size {
  .mat-mdc-paginator-page-size {
    display: none;
  }
}

.mat-mdc-dialog-container {
  padding: 0 !important;
  border-radius: 3px;
  @extend .mat-elevation-z2;
  .mat-mdc-dialog-title,
  .header {
    border-bottom: 1px solid $gray300;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    .mat-mdc-dialog-title {
      border: none;
    }
    button {
      align-self: flex-start;
      margin-left: 2ch;
      flex-shrink: 0;
      margin-bottom: 0;
      color: $gray400;
    }
  }
  .mat-mdc-dialog-title,
  .header {
    padding: 24px !important;
  }
  .mat-mdc-dialog-content {
    margin: 0;
    padding: 24px !important;
    h3 {
      font: 600 18px/1.2 'Work Sans', sans-serif;
      margin-bottom: 1rem;
    }
  }
  .mat-mdc-dialog-actions {
    display: flex;
    height: auto;
    min-height: auto;
    justify-content: flex-end;
    margin: 0;
    padding: 24px;
    flex-wrap: nowrap;
    border-top: 1px solid $gray300;
    button,
    .mat-mdc-button {
      flex-shrink: 0;
      width: auto;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

//  Elevations
.mat-elevation-z0 {
  box-shadow: none;
}
.mat-elevation-z1 {
  box-shadow: 0px 0px 2px, rbga(11, 12, 14, 0.15);
}
.mat-elevation-z2 {
  box-shadow: 0px 1px 6px, rbga(11, 12, 14, 0.15);
}
.mat-elevation-z3 {
  box-shadow: 0px 4px 12px, rbga(11, 12, 14, 0.15);
}
.mat-elevation-z4 {
  box-shadow: 4px 8px 24px, rbga(11, 12, 14, 0.2);
}
.mat-elevation-z5 {
  box-shadow: 0px 12px 32px, rbga(11, 12, 14, 0.24);
}

//  Cards
mat-card.mat-mdc-card {
  margin: 2ex 2ch;
  /* This fixes an issue where mat card padding are being overriden by the phoenix stylesheet */
  padding: 1em !important;
  @extend .mat-elevation-z1;
  border-radius: 0px;
  .mat-mdc-card-header {
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 2ex;
    .mat-mdc-card-header-text {
      margin-left: 0;
      flex-shrink: 2;
    }
    .mat-mdc-card-subtitle:not(:first-child) {
      margin-top: 1ex;
      margin-bottom: 0;
    }
    .actions {
      display: flex;
      align-items: center;
      flex-grow: 1;
      .mat-mdc-button {
        flex-shrink: 0;
      }
      button,
      a {
        margin-left: 1.5rem;
      }
    }
  }
  .mat-mdc-card-content {
    background: $white;
  }
  .mat-mdc-card-actions,
  .mat-mdc-card-actions:last-child {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-end;
    .mat-mdc-button {
      margin: 0 0 0 1ch;
    }
  }
}
@media (min-width: variables.$small) {
  mat-card.mat-mdc-card {
    .mat-mdc-card-header {
      .actions {
        flex-grow: 0;
      }
    }
  }
}

/* Button */
button {
  &.square {
    &:hover:not(:disabled) {
      border: 1px solid $gray400;
    }
    width: 36px;
    height: 36px;
    padding: 0;
  }
}

.oeo-button {
  @include oeo-mixins.oeo-button;
}
// Form Elems
.mat-mdc-checkbox .mat-mdc-checkbox-layout {
  white-space: normal;
}
