@import '@overtur-monorepo/phx-angular-material/assets/config/colors';

$toolbar-color: #404041;
$text: rgba(0, 0, 0, 0.87);

$alternate: #fe9a1d;
$error: #d7373f; // Phoenix red 500
$warning: #da7b11; // Phoenix warning / notice 500
$notice: #da7b11; // Phoenix warning / notice 500
$success: #268e6c; // Phoenix positive 500

$background-color: $gray100;

$box-shadow: 0 1px rgba(0, 0, 0, 0.24);

$text-color: $gray900;
$lighter-text: rgba(0, 0, 0, 0.6);
$lightest-text: rgba(0, 0, 0, 0.32);
$font-family: 'Work Sans', sans-serif;

//  tables
$border: $gray100;
$table-border-color: $border;
$table-header-color: rgba(0, 0, 0, 0.54);
$table-hover-color: rgba(0, 0, 0, 0.14);

//  break points
$small: 550px;
$medium: 955px;
