@use 'sass:map';
@use './assets/scss/theme.scss' as *;
@use './assets/scss/form-fields.scss';
/* Elevation Styles */
@use 'theme.scss';
/* Mixins in Common Lib */
@use 'oeo-mixins';
@use 'oeo-form-fields';
@use 'oeo-custom-styles';
@use 'oeo-variables';
@use '@overtur-monorepo/phx-angular-material/assets/config/colors' as *;
@import '@overtur-monorepo/phx-angular-material/assets/theme';
@include phoenix-design-theme();

* {
  box-sizing: border-box;
  outline: none;
  outline-color: rgba(0, 0, 0, 0);
}

html {
  background-color: oeo-variables.$background-color;
  height: 100%;
  overflow: hidden;
  font-size: 16px;
  font-weight: 400;
}

body {
  font-family: oeo-variables.$font-family;
  font-size: 16px;
  font-weight: 400;
  color: $gray900;
  margin: 0;
  padding: 0;
  height: 100%;
  line-height: 1.25;
  letter-spacing: 0.44px;

  ::-webkit-scrollbar {
    @include oeo-mixins.scroll-bar-track;
  }
  ::-webkit-scrollbar-thumb {
    @include oeo-mixins.scroll-bar;
  }
}

@keyframes loading {
  from {
    left: 0;
  }
  to {
    left: 75%;
  }
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 12px !important;
}

.load-bar {
  animation: loading 1.5s alternate ease-in-out infinite;
  background-color: $blue500;
  bottom: -2px;
  content: '';
  height: 2px;
  left: 0;
  position: absolute;
  width: 25%;
}

.card-loading {
  top: 0;
  bottom: initial;
  width: 25%;
  height: 2px;
  @extend .load-bar;
}

.loading-spinner {
  margin: 4ex auto;
}

h1 {
  border-bottom: solid 2px $gray300;
  font-size: 24px;
  font-weight: normal;
  padding: 12px 24px;
  margin: 0;
  position: relative;
  &.loading:after {
    @extend .load-bar;
  }
}

//  TODO: Delete once all modules are made responsive
h3.empty {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.15px;
  line-height: 28px;
  margin: 0;
}

/* hide arrows in firefox input[number] because of onBlur issue */
input[type='number'] {
  -moz-appearance: textfield;
}

.hidden {
  display: none;
}
.counter {
  display: block;
  margin-top: 0;
  text-align: right;
  font-size: 11px;
  line-height: normal;
}
.page-header {
  position: relative;
  border-bottom: solid 2px $gray300;
  padding: 1ex 0;
  margin: 0 16px;
  color: $gray700;
  min-height: 56px;
  h1 {
    padding: 0;
    border: none;
    font-size: 24px;
    font-weight: normal;
    margin: 0;
    vertical-align: middle;
    &:after {
      content: none;
    }
  }
  h1 small {
    display: block;
    font-size: 14px;
    text-transform: uppercase;
  }
  &.loading:after {
    @extend .load-bar;
  }
  & > div {
    align-items: center;
    display: flex;
    & > * {
      margin: 0 1ch;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  & > :first-child {
    flex-grow: 1;
  }
  & > :last-child:not(:first-child) {
    justify-content: center;
  }
  div.actions {
    justify-content: flex-end;
    margin-top: 1ex;
  }
}
@media (min-width: $small) {
  .page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    div.actions {
      justify-content: flex-end;
      margin-top: 0;
    }
  }
}

.loading {
  mat-spinner {
    margin: 32px auto;
  }
  p {
    text-align: center;
  }
}

.sr-only {
  position: absolute;
  left: -9999rem;
  top: -9999rem;
}

//  No Data Cards
.empty {
  @include oeo-mixins.typography-smaller;
  text-align: center;
  h3 {
    @include oeo-mixins.empty-header;
    text-align: center;
    width: 100%;
  }
  & > img {
    display: inline-block;
    max-height: 125px;
    margin-bottom: 2ex;
  }
  p {
    max-width: 360px;
    margin: 2ex auto;
  }
  .description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    button,
    .button {
      margin: 0 auto;
    }
  }
}
@media (min-width: oeo-variables.$small) {
  .empty {
    img {
      max-width: 50%;
      margin: 2ex auto 0;
    }
  }
}

//  errors
.snackbar-error {
  background-color: $red500 !important;
}

// font awesome
.fas {
  font-size: 1.5rem;
}

.alert:before {
  content: '* ';
  vertical-align: super;
}

.success-message {
  color: $green500;
  &:before {
    @include oeo-mixins.material-icon();
    content: 'check_circle';
    vertical-align: middle;
    margin-right: 6px;
  }
}

.info-message {
  &:before {
    content: url('/assets/img/info.svg');
    display: inline-block;
    width: 2ch;
    vertical-align: middle;
    margin-right: 6px;
  }
}

a.external-link {
  text-decoration: none;
  line-height: 0.75rem;
  &:after {
    @include oeo-mixins.material-icon();
    content: 'open_in_new';
    vertical-align: middle;
    font-size: 16px;
    padding-bottom: 2px;
  }
  &:visited {
    color: $blue500;
  }
  &:hover,
  &:active {
    color: $blue700;
  }
}

// spacer
.spacer {
  flex: 1 1 auto;
}

.full-width {
  width: 100%;
}

.error-message {
  color: $red500;
  &:before {
    content: url('/assets/img/error.svg');
    display: inline-block;
    width: 2ch;
    vertical-align: middle;
    margin-right: 6px;
  }
}

.warning-message {
  &:before {
    color: oeo-variables.$warning;
    @include oeo-mixins.material-icon();
    content: 'warning_amber';
    vertical-align: middle;
    margin-right: 6px;
  }
}

.valu-pac {
  &:before {
    content: url('/assets/img/icons/republic-pack.svg');
    display: inline-block;
    width: 2ch;
    vertical-align: middle;
    margin-right: 6px;
  }
}

.warning {
  background-image: url('/assets/img/info-warning.svg');
  background-position: calc(100% - 0.75em) center;
  background-size: 1.3125em 1.125em;
  padding-right: 2.5625em;
  background-repeat: no-repeat;
}

.warning-banner {
  background-color: #e3485014;
  padding: 1.5rem;
  margin: -1.5rem -1.5rem 1rem;
  p {
    font-size: 0.875rem;
  }
  h3 {
    font-size: 1.25rem;
    color: $red700;
  }
}

td {
  font-size: 13px;
  line-height: 13px;
  font-weight: normal;
}

.checkout.actions {
  display: flex;
  justify-content: flex-end;
  margin: 2ex 2ch;
  button,
  a {
    margin-left: 1.5rem;
  }
}

.no-link-style {
  text-decoration: none;
  :visited,
  :hover,
  :active {
    text-decoration: none;
    color: oeo-variables.$text;
  }
  color: oeo-variables.$text;
}

.link {
  color: $blue500;
  text-decoration: none;
  svg {
    fill: $blue500;
  }
  :hover,
  :visited,
  :active {
    color: $blue700;
  }
}

.uppercase {
  text-transform: uppercase;

  &::placeholder {
    text-transform: none;
  }
}

.mat-mdc-card-header .mat-mdc-card-header-text .mat-mdc-card-title {
  padding-bottom: 12px;
}
