$black100: #000000;
$black95: #0c0c0c;
$black90: #191919;
$black85: #262626;
$black80: #333333;
$black75: #3f3f3f;
$black70: #4c4c4c;
$black65: #595959;
$black60: #666666;
$black55: #727272;
$black50: #7f7f7f;
$black45: #8c8c8c;
$black40: #999999;
$black35: #a5a5a5;
$black30: #b2b2b2;
$black25: #bfbfbf;
$black20: #cccccc;
$black15: #d8d8d8;
$black10: #e5e5e5;
$black5: #f2f2f2;
$black0: #ffffff;

$blue400: #2680eb;
$blue500: #1473e6;
$blue600: #0d66d0;
$blue700: #095aba;
$blue800: #054da3;
$blueDashboard: #1f4267;

$grey50: #ffffff;
$grey75: #fafafa;
$grey100: #f5f5f5;
$grey200: #eaeaea;
$grey300: #e1e1e1;
$grey400: #cacaca;
$grey500: #b3b3b3;
$grey700: #6e6e6e;
$grey800: #4b4b4b;
$grey900: #2c2c2c;

$border: 1px solid $black10;
$box-shadow: 0 2px 1px -1px rgba($black100, 0.2), 0 1px 1px 0 rgba($black100, 0.14), 0 1px 3px 0 rgba($black100, 0.12);
$border-radius: 2px;
$tiny-range: 4px;
$small-range: 8px;
$large-range: 16px;

$doorStrokeThickness: 16px;

.dialog-container {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  background: rgba(0, 0, 0, 0.35);
  height: 100%;
  width: 100%;
  z-index: 100;
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14),
    0 9px 46px 8px rgba(0, 0, 0, 0.12);

  & > * {
    transform-origin: 50% 50%;
    animation: growIn ease-in 0.15s;
    background: white;
    margin: auto;
    border-radius: 0 0 2px 2px;
    box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14),
      0 9px 46px 8px rgba(0, 0, 0, 0.12);
  }
}

@keyframes growIn {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

lib-elevation {
  .select-component {
    .placeholder {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  mat-radio-group mat-radio-button:not(:last-child) {
    margin-right: 20px !important;
  }

}

.phx-panel-container {
  overflow: auto !important;
  max-height: 160px;

  .option {
    height: auto !important;
  }
}

span.lib-open-in-new-label{
  display: flex !important;
  flex-direction: row;
  font-size: 1.25em;
  a{
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #2680eb;
    &:active, &:hover, &:focus{
      text-decoration: none;
      color: #2680eb;
    }
    i{
      font-size: 1em;
      vertical-align: text-top;
    }
  }
}



.lib-prep-container{
  display: grid;
  grid-template-columns: 2fr 3fr;
  width: 100%;
  overflow: hidden;
}

.lib-radio-group{
  padding-bottom: 1em;
  display: flex;
  flex-direction: row;
  gap: 1em;
}
