@use 'sass:map';
@use './theme.scss';
@use 'oeo-variables';
@use 'oeo-mixins';
@use 'oeo-form-fields';
@import '@overtur-monorepo/phx-angular-material/assets/config/colors';

.oeo-label,
input {
  display: block;
}

.oeo-label,
.oeo-legend {
  // .mat-checkbox-label set in theme file
  font-weight: 400;
  margin-bottom: 0.75rem;

  .mat-mdc-error {
    letter-spacing: 0.4px;
    font-size: 12px;
    text-transform: initial;
  }
  .datepicker {
    align-items: center;
    display: flex;
  }
  span {
    display: block;
  }
  span.required::before {
    content: '* ';
  }
}

.detail {
  font-weight: normal;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.66px;
}

.oeo-input {
  @include oeo-mixins.form-field;
}

fieldset,
legend {
  border: none;
  display: block;
  margin: 0;
  padding: 0;
}

input {
  &.currency {
    @include oeo-mixins.input-type('money');
  }
  /*  Search Input */
  &[type='search'] {
    @include oeo-mixins.input-type('search');
    @include oeo-mixins.form-field;
  }
}

@media (min-width: oeo-variables.$small) {
  input[type='search'] {
    width: 148px;
    margin-left: 1ch;
  }
}

$placeholder-color: $gray600;
.placeholder {
  color: $placeholder-color;
}
::-webkit-input-placeholder {
  color: $placeholder-color;
  font-weight: lighter;
}
::-moz-placeholder {
  color: $placeholder-color;
  font-weight: lighter;
}
:-ms-input-placeholder {
  color: $placeholder-color;
  font-weight: lighter;
}
:-moz-placeholder {
  color: $placeholder-color;
  font-weight: lighter;
}
:placeholder {
  color: $placeholder-color;
  font-weight: lighter;
}
