@use './oeo-variables.scss' as variables;
@use './oeo-mixins.scss' as mixins;
@import '@overtur-monorepo/phx-angular-material/assets/config/colors';

.oeo-input {
  @include mixins.form-field;
}

input {
  &.currency {
    @include mixins.input-type('money');
  }
  //  Search Input
  &[type='search'] {
    &:not(.filter-input) {
      @include mixins.input-type('search');
    }
    &.filter-input {
      @include mixins.input-type('filter');
    }
    height: 32px;
  }
  &.oeo-search-input {
    width: 100%;
    position: relative;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    font-family: 'Work Sans', sans-serif;
    padding-left: 2.5ch;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyNC4zLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAyNCAyNCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjQgMjQ7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+DQoJLnN0MHtmaWxsOiMzOTNFNDY7fQ0KPC9zdHlsZT4NCjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0yLjUsOS44YzAsNC4xLDMuNCw3LjUsNy41LDcuNWMxLjgsMCwzLjQtMC42LDQuNy0xLjdsNiw2bDEuMS0xLjFsLTYtNmMxLjEtMS4zLDEuNy0yLjksMS43LTQuNw0KCWMwLTQuMS0zLjQtNy41LTcuNS03LjVTMi41LDUuNiwyLjUsOS44eiBNMTYsOS44YzAsMy4zLTIuNyw2LTYsNnMtNi0yLjctNi02YzAtMy4zLDIuNy02LDYtNlMxNiw2LjQsMTYsOS44eiIvPg0KPC9zdmc+DQo=);
    background-size: 1.75ch;
    background-repeat: no-repeat;
    background-position: left 0ch bottom 0ch;
  }
}

::ng-deep {
  mat-form-field {
    &.no-hint {
      .mat-mdc-form-field-wrapper {
        height: 2.5em;
      }
      .mat-mdc-form-field-underline {
        display: none;
      }
      .mat-mdc-form-field-subscript-wrapper {
        display: none;
      }
    }
    input {
      &.mat-mdc-input-element {
        font-family: 'Work Sans', sans-serif;
      }
      margin: 0 !important;
    }
    .mat-mdc-form-field-should-float label,
    mat-label {
      font-size: 1.15em;
      font-family: 'Work Sans', sans-serif;
    }
  }
}
